import { FcOk, FcOvertime, FcInfo } from "react-icons/fc";
import { AiOutlineMenu } from "react-icons/ai";
import { RiCustomerService2Fill, RiSpyFill } from "react-icons/ri";
import { FiAlertTriangle, FiFlag, FiInfo, FiSearch, FiHelpCircle, FiCheckSquare, FiTrash2, FiEye, FiPlus, FiCheck, FiCalendar, FiUser, FiClock, FiSettings, FiClipboard, FiChevronUp, FiChevronDown, FiRefreshCcw, FiDelete } from "react-icons/fi";
import { MdOutlineKeyboardArrowDown, MdOutlineCameraAlt } from "react-icons/md";
import { GoGraph } from "react-icons/go";
import { PiPuzzlePiece } from "react-icons/pi";
import { RiLogoutBoxLine } from "react-icons/ri";
import { MdOutlineSaveAlt } from "react-icons/md";
import { MdOutlineCancel } from "react-icons/md";
import { FaEdit, FaRegListAlt } from "react-icons/fa";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { BsCardList } from "react-icons/bs";
import { LuUpload } from 'react-icons/lu'
import { CiImageOn } from "react-icons/ci";
import { FaRegImage } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegFileImage } from "react-icons/fa6";
import { GoFileMedia } from "react-icons/go";

const icones = {
  AiOutlineMenu,
  FiAlertTriangle,
  FcOk,
  FcOvertime,
  FcInfo,
  RiCustomerService2Fill,
  FiSearch,
  FiHelpCircle,
  FiCheckSquare,
  FiTrash2,
  FiEye,
  FiPlus,
  FiCheck,
  FiCalendar,
  FiUser,
  FiClock,
  FiSettings,
  FiClipboard,
  FiChevronUp,
  FiChevronDown,
  FiRefreshCcw,
  FiDelete,
  FiInfo,
  GoGraph,
  PiPuzzlePiece,
  RiSpyFill,
  MdOutlineKeyboardArrowDown,
  RiLogoutBoxLine,
  MdOutlineSaveAlt,
  MdOutlineCancel,
  FaEdit,
  HiOutlinePencilAlt,
  LuUpload,
  MdOutlineCameraAlt,
  BsCardList,
  FaRegListAlt,
  CiImageOn,
  FaRegImage,
  FaCheck,
  AiOutlineClose,
  FaRegFileImage,
  GoFileMedia,
  FiFlag  
};

export default icones;
