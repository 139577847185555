import { useState, useEffect } from 'react'

import { Dock } from 'react-dock'
import toast from 'react-hot-toast';

import UploadComponent from '../../../../../components/UploadComponent';

import api from '../../../../../api/api'
import './tarefa.css'

function Tarefa() {

    const [descricao, setDescricao] = useState('');
    const [clientes, setClientes] = useState([]);
    const [clienteSelecionado, setClienteSelecionado] = useState({
        codi_pesso: '',
        nome_fanta: ''
    })

    const [solicitacao, setSolicitacao] = useState('Selecionar');
    const [atribuida, setAtribuida] = useState('');
    const [prazo, setPrazo] = useState('');
    const [hora, setHora] = useState('');

    const [show, setShow] = useState(false); 

    const [imagens, setImagens] = useState([]);
    const [progressBar, setProgressBar] = useState({ started: false, pc: 0 });
    const [resetImages, setResetImages] = useState(false);


    let tipo_supor = ''

    if(verificaEquipe){
        tipo_supor = '1'
    }else{
        tipo_supor = '2'
    }

    useEffect( function(){
        window.addEventListener('openSidebar', function(){
            setShow(true);            
        });
    }, []);

    function verificaEquipe() {
        const usuario = localStorage.getItem("suporte_usuario");
        return usuario === "andre" || usuario === "samuel" || usuario === "bento" || usuario === "rodimilson";
    }

    useEffect(()=>{
        const BuscarClientes = async () => {
            try {
                const response = await api.get(`/consulta/cliente/*|${localStorage.getItem("suporte_usuario")}`);
                setClientes(response.data.dados);
              } catch (err) {
                console.log(err)
              }
        }
        BuscarClientes();
    },[])

    

    const SalvarTarefa = () => {

        const dataAtual = () => {
            const data = new Date();
            return new Date(Date.UTC(data.getUTCFullYear(), data.getUTCMonth(), data.getUTCDate()));
        }
       
        const formataData = (data) => {
            const partes = data.split('-');
            return new Date(Date.UTC(parseInt(partes[0]), parseInt(partes[1]) - 1, parseInt(partes[2])));
        }
     
        const formatarParaExibicao = (data) => {
            const dia = String(data.getUTCDate()).padStart(2, '0');
            const mes = String(data.getUTCMonth() + 1).padStart(2, '0'); 
            const ano = data.getUTCFullYear();
            return `${dia}/${mes}/${ano}`;
        }

        const dataPrazo = formataData(prazo);
        const dataPrazoFormatada = formatarParaExibicao(dataPrazo);

        if (dataPrazo < dataAtual()) {
            alert('Data menor que o permitido');
            return;
        }
    
        if (!clienteSelecionado || !descricao || solicitacao === "Selecionar" || atribuida === "Selecionar" || prazo === '' || hora === '' || clienteSelecionado.nome_fanta === '' || clienteSelecionado.codi_pesso === '') {
            alert('Todas as informações são necessárias');
            return;
        }

        const form = new FormData();
        
        form.append('login', localStorage.getItem('suporte_usuario'));
        form.append('tipo_pesso', "C");
        form.append('codi_pesso', clienteSelecionado.codi_pesso);
        form.append('nome_conta', clienteSelecionado.nome_fanta);
        form.append('tipo_lanca', solicitacao);
        form.append('desc_lanca', descricao);
        form.append('nome_respo', atribuida);
        form.append('data_prazo', dataPrazoFormatada);
        form.append('hora_prazo', hora);
        form.append('tipo_supor', tipo_supor);
        form.append('desc_statu', "0");
        
        if(!imagens){
            form.append('file', null)
        }else{

            for(let i = 0; i < imagens.length; i++){
                form.append(`file${i+1}`, imagens[i])
            }

            api.post('/cadastro/tarefas', form, {
            onUploadProgress: (progressEvent) => { setProgressBar(prevState => {
               return {...prevState, pc: progressEvent.progress * 100}
            })},
            headers: { 
                'Content-Type': 'multipart/form-data'
            }})
            .then((res)=> {
                if(res.status === 200) {
                    toast.success("Tarefa adicionada!")
                    setResetImages(true);
                    setProgressBar({ started: false, pc: 0 });
                    setImagens([]);
                    setSolicitacao('Selecionar');
                    setAtribuida('Selecionar');
                    setClienteSelecionado({ codi_pesso: '', nome_fanta: '' });
                    setPrazo('');
                    setHora('');
                    setDescricao('');
                }
            })
           .catch(err => {
                toast.error("Erro ao tentar criar tarefa!")
           })            
        };
    }
   
    const handleSelecionaCliente = (e) => {
        const [codi_pesso, nome_fanta] = e.target.value.split('|');
        setClienteSelecionado({ codi_pesso, nome_fanta });
    }

    const handleImagesChange = (newImages) => {
        setImagens(newImages); 
        setResetImages(false);
    };

    return (
        <Dock
            position="right"
            isVisible={show}
            fluid={false}
            size={420}
            onVisibleChange={ function(visible) {
                setShow(visible);
        }}
        >
         <h1 className='text-center titulo'>Criando Tarefa</h1>
          <div className='criacao-tarefa-container'>
            <div>
                <div className='criacao-tarefa-secao'>  
                    <div className='secao'>
                        <h3>Criada por:</h3>
                        <input 
                            type="text"
                            value={localStorage.getItem('suporte_usuario')}
                            disabled
                            className='input'/>
                    </div>

                    <div className='secao'>
                        <h3>Solicitação:</h3>
                        <select className='input' value={solicitacao} onChange={(e) => setSolicitacao(e.target.value)}>
                            <option value="Selecionar">Selecionar</option> 
                            <option value={0}>Correção</option> 
                            <option value={1}>Desenvolvimento</option>
                            <option value={2}>Relatório</option>
                            <option value={3}>Implantação</option>
                        </select>
                    </div>
                </div>

                <div className='criacao-tarefa-secao'>  
                    <div className='secao'>
                        <h3>Atribuir a:</h3>
                        <select className='input' value={atribuida} onChange={(e) => setAtribuida(e.target.value)}>
                            <option value="0">Selecionar</option> 
                            <option value="André">André</option>
                            <option value="Adriano">Adriano</option> 
                            <option value="Bento">Bento</option>
                            <option value="Dasniel">Dasniel</option>
                            <option value="Didio">Didio</option>
                            <option value="Equipe de de Desenvolvimento Syshorti">Equipe de Desenvolvimento SysHorti</option>
                            <option value="Equipe de de Desenvolvimento Web">Equipe de Desenvolvimento Web</option>
                            <option value="Suporte Curitiba">Suporte Curitiba</option>
                            <option value="Suporte Rodinfo">Suporte Rodinfo</option>
                            <option value="Rodimilson">Rodimilson</option>
                            <option value="Victor">Victor</option>
                        </select>
                    </div>
                </div>

                <div className='criacao-tarefa-secao'>
                    <div className='secao'>
                        <h3>Cliente</h3>
                        <select className='input' 
                            value={clienteSelecionado.codi_pesso ? `${clienteSelecionado.codi_pesso}|${clienteSelecionado.nome_fanta}` : ''}
                            onChange={handleSelecionaCliente}>
                            <option value="">Selecionar cliente</option>
                            {clientes
                                .slice()
                                .sort((a, b) => a.nome_fanta.localeCompare(b.nome_fanta))
                                .map((item, index) => (
                                    <option key={index} value={`${item.codi_pesso}|${item.nome_fanta}`}>
                                        {item.nome_fanta}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>

                <div className='criacao-tarefa-secao'>
                    <div className='secao'>
                        <h3>Prazo:</h3>
                        <input className='input' type='date' value={prazo} onChange={(e) => setPrazo(e.target.value)}/>
                    </div>

                    <div className='secao'>
                        <h3>Hora:</h3>
                        <input className='input' value={hora} type='time' onChange={(e) => setHora(e.target.value)}/>
                    </div>
                </div>

                <div className='criacao-tarefa-secao'>
                    <div className='secao'>
                        <h3>Descrição:</h3> 
                        <textarea className='text-area-tarefa' maxLength={500} value={descricao} onChange={(e) => setDescricao(e.target.value)}/>
                    </div>
                </div>

                <div className='criacao-tarefa-secao'>
                    <div className='secao'>
                        <h3>Exemplos e evidências:</h3>                       
                        <UploadComponent type='TA' onImagesChange={handleImagesChange} resetTrigger={resetImages}/> 
                    </div>
                </div>          
                     
            
                <div>
                    <div  className='criacao-tarefa-secao'>
                        <div className='acao-container'>
                            <button className='btn-salvar' onClick={SalvarTarefa}>Salvar</button>
                            <button className='btn-cancelar' onClick={() => setShow(false)}>Cancelar</button>
                        </div>
                    </div>
                </div>

            </div>
          </div>
        </Dock>
    )
} 

export default Tarefa;