import { useEffect, useState } from 'react';
import Modal from 'react-modal'

import api from '../../api/api';

import Zoom from 'react-medium-image-zoom';

import { Show } from '../Show/Show'
import LoadingSpinner from '../loading-spinner/LoadingSpinner';


import 'react-medium-image-zoom/dist/styles.css';
import styles from './styles.module.css'


const customStyles = {
    content: {
        top: '52.5%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'hidden'
    },
};

export default function ViewImageModalTarefa({ show, id, handleCloseModal }) {
    const [imagens, setImagens] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (show) {
            async function carregaChamado() {
                try {
                    const response = await api.get(`/detalhe/tarefas/${id}|${localStorage.getItem('suporte_usuario')}`);
                    setImagens(response.data.dados);
                    console.log(imagens)
                    setLoading(false);
                } catch (error) {
                    console.error('Erro ao carregar imagens');
                    setLoading(false);
                }
            }
            carregaChamado();
        }
        setLoading(false);
    }, [show, id]);

    
    return (
        <Modal
            isOpen={show}
            style={customStyles}
            onRequestClose={handleCloseModal}
        >
          <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                <h2>Consultando fotos</h2>
            </div>

            <div className={styles.title}>
                <h3>Id: {id}</h3>
                <h3>Imagens: {imagens.length}</h3>
            </div>

            <div className={styles.modalBox}>
                <Show>
                    <Show.When isTrue={loading}>
                        <LoadingSpinner />
                    </Show.When>
                </Show>

                <Show>
                    <Show.When isTrue={!loading && imagens.length > 0}>
                        <div className={styles.imagesContainer}>
                          {imagens.map((imagem, index)=>(
                            <Zoom key={index}>
                              <img 
                                src={imagem.foto_taref}
                                className={styles.imagem}
                                alt='Foto da tarefa'
                                loading='lazy'
                              />  
                            </Zoom>
                          ))}
                        </div>
                    </Show.When>

                    <Show.When isTrue={!loading && imagens.length <= 0}>
                        <span style={{display: 'flex', width: '100%', justifyContent: 'center'}}s>
                            <p>Nenhuma imagem registrada.</p>
                        </span>
                    </Show.When>
                </Show>
                
            </div>
            <div className={styles.actions}>
                <div>
                    <button className={styles.closeModal} onClick={handleCloseModal}>
                        Fechar
                    </button>
                </div>
            </div>
        </Modal>
    );
}
