import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Toaster } from 'react-hot-toast'
import ReactDOM from 'react-dom/client';

import App from './App';

import Sobre from "../src/pages/institucional/sobre/sobre";
import Hortifruti from "./pages/segmentos/hortifruti/hortifruti";
import Industria from "./pages/segmentos/industria/industria";
import Laboratorio from "./pages/segmentos/laboratorio/laboratorio";
import Varejo from "./pages/segmentos/varejo/varejo";
import Contato from './pages/contato/contato'
import Transporte from './pages/segmentos/transporte/transporte'
import Condominio from './pages/segmentos/condominio/condominio'
import Imobiliaria from './pages/segmentos/imobiliaria/imobiliaria'
import Suporte from "./pages/suporte/suporte";
import Clientes from "./pages/clientes/clientes";
import Politicas from "./pages/politicas/politicas";

import PrivateRoute from "./components/private-route/private-route";

import Chamados from "./pages/chamados/chamados";
import Tarefas from "./pages/tarefas/tarefas";
import Manuais from "./pages/manuais/manuais";
import Monitoramento from "./pages/monitoramento/monitoramento";
import Recorrencias from "./pages/recorrencias/recorrencias";
import Gabarito from './pages/gabarito'
import RegistrarResolucao from "./pages/registrar-resolucao";

import DetalhesResolucao from "./pages/detalhe-resolucao";

import './index.css';

const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,    
    },
    {
      path: "/politicas",
      element: <Politicas />,
    },
   {
      path: "/empresa",
      element: <Sobre />,
    },
    {
      path: "/segmentos/hortifruti",
      element: <Hortifruti />,
    },
    {
      path: "/segmentos/industria",
      element: <Industria />,
    },
    {
      path: "/segmentos/laboratorio",
      element: <Laboratorio />,
    },
    {
      path: "/segmentos/varejo",
      element: <Varejo />,
    },
    {
      path: "/contato",
      element: <Contato />,
    },
    {
      path: "/segmentos/transporte",
      element: <Transporte />,
    },
    {
      path: "/segmentos/condominio",
      element: <Condominio />,
    },
    {
      path: "/segmentos/imobiliaria",
      element: <Imobiliaria />,
    },
    {
      path: "/suporte/",
      element: <Suporte />,
    },
    {
      path: "/restrito/clientes",
      element: 
        <PrivateRoute>
          <Clientes />
        </PrivateRoute>,
    },
    {
      path: "/restrito/chamados",
      element: 
        <PrivateRoute>
          <Chamados />
        </PrivateRoute>,
    },
    {
      path: "/restrito/adicionar-resolucao",
      element: 
        <PrivateRoute>
          <RegistrarResolucao />
        </PrivateRoute>,
    },
    {
      path: "restrito/detalhes-resolucao/:id",
      element: 
        <PrivateRoute>
          <DetalhesResolucao />
        </PrivateRoute>,
    },
    {
      path: "/restrito/gabarito",
      element: 
        <PrivateRoute>
          <Gabarito />
        </PrivateRoute>,
    },
    {
      path: "/restrito/tarefas",
      element: 
        <PrivateRoute>
          <Tarefas />
        </PrivateRoute>,
    },
    {
      path: "/restrito/manuais",
      element: 
        <PrivateRoute>
          <Manuais />
        </PrivateRoute>,
    },
    {
      path: "/restrito/monitoramento",
      element: 
        <PrivateRoute>
          <Monitoramento />
        </PrivateRoute>,
    },
    {
      path: "/restrito/recorrencias",
      element: 
        <PrivateRoute>
          <Recorrencias />
        </PrivateRoute>,
    }
  ]);

  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
      <RouterProvider router={router} />
        <Toaster
           position='top-center'
           reverseOrder={false}
        />
  </>
);


